<template>
  <Stack tag="div" gap="md" class="MkmFooterSocialMenu">
    <br-manage-menu-button v-if="page.isPreview()" class="edit-social-menu-button-cms" :menu="document" />

    <NuxtLink v-for="(social, index) in socialMediaIcons" :to="social.url" :key="index" data-test-id="socialMediaLink">
      <NuxtPicture
        :src="`/icons/social/${social.name}_white.svg`"
        :alt="social.name"
        loading="lazy"
        :img-attrs="{ class: 'h-md sm:h-lg' }"
      />
    </NuxtLink>
  </Stack>
</template>
<script lang="ts" setup>
import type { Page, Content, Component } from "@bloomreach/spa-sdk";
const props = defineProps<{ page: Page; component: Component }>();

interface ContentWithItems extends Content {
  items: any;
  getItems: () => any;
}

const { menu: menuRef } = props.component.getModels();
const document = props.page.getContent(menuRef) as ContentWithItems;

const socialMediaIcons = document.getItems().map((item: any) => {
  return {
    url: item.getUrl(),
    name: item.getName(),
  };
});
</script>
